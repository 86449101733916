<svg class="app-logo" [attr.width]="width" [attr.height]="height" viewBox="0 0 110 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.15209 15.561C6.62767 13.0321 7.03858 8.52582 10.0711 5.49332C13.105 2.45944 17.6139 2.04954 20.1422 4.57776L15.5669 9.153C18.0956 6.62757 22.6026 7.03821 25.6355 10.0711C28.6693 13.105 29.0793 17.6139 26.551 20.1422L21.9749 15.566C24.5013 18.0946 24.0909 22.6023 21.0577 25.6355C18.0238 28.6693 13.5149 29.0793 10.9866 26.551L15.5615 21.9762C13.0327 24.5012 8.52601 24.0904 5.49331 21.0577C2.45944 18.0238 2.04953 13.5149 4.57776 10.9866L9.15209 15.561ZM15.3813 9.33864C18.5857 12.5431 18.7688 18.7688 15.7475 21.7901C12.5431 18.5857 12.36 12.36 15.3813 9.33864Z" [attr.fill]="'#' + iconColor"/>
  <path d="M102.861 23.8349H100.055V12.8233H100.733L101.658 13.8926C102.111 13.4814 102.622 13.1661 103.19 12.9467C103.766 12.7205 104.366 12.6074 104.99 12.6074C105.661 12.6074 106.295 12.7377 106.892 12.9981C107.488 13.2517 108.009 13.6047 108.455 14.0571C108.9 14.5027 109.25 15.027 109.503 15.6302C109.764 16.2265 109.894 16.864 109.894 17.5426V23.8349H107.087V17.5426C107.087 17.2547 107.032 16.9839 106.923 16.7303C106.813 16.4699 106.662 16.2437 106.47 16.0517C106.278 15.8598 106.056 15.709 105.802 15.5994C105.548 15.4897 105.278 15.4349 104.99 15.4349C104.695 15.4349 104.417 15.4897 104.157 15.5994C103.896 15.709 103.67 15.8598 103.478 16.0517C103.286 16.2437 103.136 16.4699 103.026 16.7303C102.916 16.9839 102.861 17.2547 102.861 17.5426V23.8349Z" [attr.fill]="'#' + textColor"/>
  <path d="M93.3716 21.1407C93.4812 21.175 93.5909 21.199 93.7006 21.2127C93.8103 21.2195 93.9199 21.2229 94.0296 21.2229C94.3038 21.2229 94.5677 21.1852 94.8213 21.1098C95.0749 21.0345 95.3114 20.9282 95.5307 20.7911C95.7569 20.6472 95.9557 20.4758 96.127 20.277C96.3052 20.0714 96.4492 19.8452 96.5589 19.5985L98.6152 21.6651C98.3547 22.0352 98.0531 22.3676 97.7104 22.6624C97.3745 22.9571 97.0078 23.2073 96.6103 23.4129C96.2196 23.6186 95.8049 23.7728 95.3662 23.8756C94.9344 23.9853 94.4888 24.0401 94.0296 24.0401C93.255 24.0401 92.5251 23.8962 91.8396 23.6083C91.161 23.3204 90.5647 22.9194 90.0506 22.4053C89.5434 21.8912 89.1424 21.2812 88.8477 20.5752C88.5529 19.8624 88.4056 19.081 88.4056 18.231C88.4056 17.3605 88.5529 16.5654 88.8477 15.8457C89.1424 15.126 89.5434 14.5125 90.0506 14.0053C90.5647 13.4981 91.161 13.1039 91.8396 12.8229C92.5251 12.5419 93.255 12.4014 94.0296 12.4014C94.4888 12.4014 94.9378 12.4562 95.3765 12.5659C95.8152 12.6755 96.2298 12.8332 96.6205 13.0388C97.0181 13.2445 97.3882 13.4981 97.731 13.7997C98.0737 14.0944 98.3753 14.4268 98.6357 14.797L93.3716 21.1407ZM94.811 15.3522C94.6808 15.3042 94.5505 15.2733 94.4203 15.2596C94.2969 15.2459 94.1667 15.2391 94.0296 15.2391C93.6457 15.2391 93.2825 15.311 92.9397 15.455C92.6039 15.5921 92.3091 15.7909 92.0555 16.0513C91.8088 16.3118 91.6134 16.6271 91.4695 16.9972C91.3255 17.3605 91.2536 17.7718 91.2536 18.231C91.2536 18.3338 91.257 18.4504 91.2639 18.5806C91.2776 18.7108 91.2947 18.8445 91.3153 18.9816C91.3427 19.1118 91.3735 19.2386 91.4078 19.362C91.4421 19.4854 91.4866 19.595 91.5415 19.691L94.811 15.3522Z" [attr.fill]="'#' + textColor"/>
  <path d="M83.2854 23.8348H80.4785V12.8232H81.1571L82.0824 14.129C82.5348 13.7177 83.0489 13.4024 83.6247 13.1831C84.2004 12.9569 84.7968 12.8438 85.4137 12.8438H87.8915V15.6404H85.4137C85.1189 15.6404 84.8413 15.6952 84.5809 15.8049C84.3204 15.9146 84.0942 16.0654 83.9023 16.2573C83.7104 16.4492 83.5596 16.6754 83.4499 16.9359C83.3402 17.1963 83.2854 17.4739 83.2854 17.7687V23.8348Z" [attr.fill]="'#' + textColor"/>
  <path d="M78.8129 9.8312C78.8129 10.0917 78.7615 10.335 78.6587 10.5612C78.5627 10.7874 78.429 10.9862 78.2577 11.1575C78.0863 11.322 77.8841 11.4557 77.6511 11.5585C77.4249 11.6545 77.1815 11.7024 76.9211 11.7024C76.6606 11.7024 76.4139 11.6545 76.1808 11.5585C75.9546 11.4557 75.7558 11.322 75.5845 11.1575C75.42 10.9862 75.2863 10.7874 75.1835 10.5612C75.0875 10.335 75.0396 10.0917 75.0396 9.8312C75.0396 9.57759 75.0875 9.33769 75.1835 9.1115C75.2863 8.87845 75.42 8.67967 75.5845 8.51517C75.7558 8.34381 75.9546 8.21015 76.1808 8.11418C76.4139 8.01137 76.6606 7.95996 76.9211 7.95996C77.1815 7.95996 77.4249 8.01137 77.6511 8.11418C77.8841 8.21015 78.0863 8.34381 78.2577 8.51517C78.429 8.67967 78.5627 8.87845 78.6587 9.1115C78.7615 9.33769 78.8129 9.57759 78.8129 9.8312ZM78.3296 23.8347H75.5022V12.8231H78.3296V23.8347Z" [attr.fill]="'#' + textColor"/>
  <path d="M70.5054 23.8347H64.0897V21.0382H70.5054C70.6973 21.0382 70.8618 20.9696 70.9989 20.8325C71.136 20.6954 71.2046 20.5309 71.2046 20.339C71.2046 20.1402 71.136 20.01 70.9989 19.9483C70.8618 19.8866 70.6973 19.8558 70.5054 19.8558H67.5855C67.0988 19.8558 66.643 19.7632 66.218 19.5782C65.793 19.3931 65.4229 19.1429 65.1076 18.8276C64.7923 18.5055 64.5421 18.1319 64.357 17.7069C64.1788 17.282 64.0897 16.8261 64.0897 16.3395C64.0897 15.8528 64.1788 15.397 64.357 14.972C64.5421 14.5471 64.7923 14.1769 65.1076 13.8616C65.4229 13.5463 65.793 13.2996 66.218 13.1214C66.643 12.9363 67.0988 12.8438 67.5855 12.8438H73.2712V15.6403H67.5855C67.3935 15.6403 67.229 15.7089 67.0919 15.846C66.9548 15.9831 66.8863 16.1476 66.8863 16.3395C66.8863 16.5383 66.9548 16.7096 67.0919 16.8536C67.229 16.9906 67.3935 17.0592 67.5855 17.0592H70.5054C70.9852 17.0592 71.4376 17.1414 71.8626 17.3059C72.2876 17.4636 72.6577 17.6864 72.973 17.9742C73.2883 18.2621 73.5385 18.6083 73.7235 19.0127C73.9086 19.4171 74.0011 19.8592 74.0011 20.339C74.0011 20.8257 73.9086 21.2815 73.7235 21.7065C73.5385 22.1246 73.2883 22.4947 72.973 22.8169C72.6577 23.1322 72.2876 23.3823 71.8626 23.5674C71.4376 23.7456 70.9852 23.8347 70.5054 23.8347Z" [attr.fill]="'#' + textColor"/>
  <path d="M57.7768 21.1407C57.8865 21.175 57.9961 21.199 58.1058 21.2127C58.2155 21.2195 58.3251 21.2229 58.4348 21.2229C58.709 21.2229 58.9729 21.1852 59.2265 21.1098C59.4801 21.0345 59.7166 20.9282 59.9359 20.7911C60.1621 20.6472 60.3609 20.4758 60.5322 20.277C60.7105 20.0714 60.8544 19.8452 60.9641 19.5985L63.0204 21.6651C62.7599 22.0352 62.4583 22.3676 62.1156 22.6624C61.7797 22.9571 61.413 23.2073 61.0155 23.4129C60.6248 23.6186 60.2101 23.7728 59.7714 23.8756C59.3396 23.9853 58.894 24.0401 58.4348 24.0401C57.6603 24.0401 56.9303 23.8962 56.2448 23.6083C55.5662 23.3204 54.9699 22.9194 54.4558 22.4053C53.9486 21.8912 53.5476 21.2812 53.2529 20.5752C52.9582 19.8624 52.8108 19.081 52.8108 18.231C52.8108 17.3605 52.9582 16.5654 53.2529 15.8457C53.5476 15.126 53.9486 14.5125 54.4558 14.0053C54.9699 13.4981 55.5662 13.1039 56.2448 12.8229C56.9303 12.5419 57.6603 12.4014 58.4348 12.4014C58.894 12.4014 59.343 12.4562 59.7817 12.5659C60.2204 12.6755 60.6351 12.8332 61.0258 13.0388C61.4233 13.2445 61.7934 13.4981 62.1362 13.7997C62.4789 14.0944 62.7805 14.4268 63.0409 14.797L57.7768 21.1407ZM59.2162 15.3522C59.086 15.3042 58.9557 15.2733 58.8255 15.2596C58.7021 15.2459 58.5719 15.2391 58.4348 15.2391C58.051 15.2391 57.6877 15.311 57.345 15.455C57.0091 15.5921 56.7144 15.7909 56.4607 16.0513C56.214 16.3118 56.0186 16.6271 55.8747 16.9972C55.7308 17.3605 55.6588 17.7718 55.6588 18.231C55.6588 18.3338 55.6622 18.4504 55.6691 18.5806C55.6828 18.7108 55.6999 18.8445 55.7205 18.9816C55.7479 19.1118 55.7787 19.2386 55.813 19.362C55.8473 19.4854 55.8918 19.595 55.9467 19.691L59.2162 15.3522Z" [attr.fill]="'#' + textColor"/>
  <path d="M51.248 23.8349H50.5694L49.4796 22.3235C49.2123 22.5634 48.9278 22.7896 48.6262 23.002C48.3315 23.2077 48.0196 23.3893 47.6906 23.547C47.3616 23.6978 47.0223 23.8177 46.6727 23.9068C46.33 23.9959 45.9804 24.0405 45.624 24.0405C44.8495 24.0405 44.1195 23.8965 43.434 23.6087C42.7555 23.3139 42.1591 22.9095 41.645 22.3954C41.1378 21.8745 40.7368 21.261 40.4421 20.555C40.1474 19.8422 40 19.0676 40 18.2314C40 17.402 40.1474 16.6309 40.4421 15.9181C40.7368 15.2052 41.1378 14.5883 41.645 14.0674C42.1591 13.5464 42.7555 13.1386 43.434 12.8439C44.1195 12.5491 44.8495 12.4018 45.624 12.4018C45.8708 12.4018 46.1244 12.4223 46.3848 12.4634C46.6522 12.5046 46.9092 12.5731 47.156 12.6691C47.4096 12.7582 47.6461 12.8781 47.8654 13.0289C48.0847 13.1797 48.2698 13.3648 48.4206 13.5841V8.44336H51.248V23.8349ZM48.4206 18.2314C48.4206 17.8476 48.3452 17.4774 48.1944 17.121C48.0505 16.7577 47.8517 16.439 47.5981 16.1648C47.3445 15.8838 47.0463 15.661 46.7036 15.4965C46.3677 15.3251 46.0079 15.2395 45.624 15.2395C45.2402 15.2395 44.8769 15.308 44.5342 15.4451C44.1983 15.5822 43.9036 15.781 43.65 16.0414C43.4032 16.295 43.2078 16.6069 43.0639 16.9771C42.92 17.3472 42.848 17.7653 42.848 18.2314C42.848 18.6358 42.92 19.0197 43.0639 19.3829C43.2078 19.7462 43.4032 20.0649 43.65 20.3391C43.9036 20.6133 44.1983 20.8292 44.5342 20.9869C44.8769 21.1445 45.2402 21.2233 45.624 21.2233C46.0079 21.2233 46.3677 21.1411 46.7036 20.9766C47.0463 20.8052 47.3445 20.5825 47.5981 20.3083C47.8517 20.0272 48.0505 19.7085 48.1944 19.3521C48.3452 18.9888 48.4206 18.6153 48.4206 18.2314Z" [attr.fill]="'#' + textColor"/>
</svg>
